<template>
  <div>
    <v-dialog
      v-model="isEditCustomerGroup"
      max-width="400"
      persistent
    >
      <v-card>
        <v-form
          ref="formEditCustomerGroup"
          @submit.prevent="updateCustomerGroupData"
        >
          <v-card-title>
            {{ $t('edit_customer_group') }}
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model.trim="shopName"
              readonly
              :label="$t('clinic_name')"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="dataEditLocal.customer_group_name"
              :label="$t('group_customers')"
              class="mt-3"
              :rules="[required]"
              autofocus
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model.trim="dataEditLocal.customer_group_save"
              :label="$t('discount_amount')"
              type="number"
              outlined
              class="mt-3"
              dense
            ></v-text-field>
            <v-select
              v-model="dataEditLocal.save_type_id"
              dense
              outlined
              class="mt-3"
              :label="$t('discount_type')"
              item-value="id"
              :items="discountTypeList"
              :item-text="$i18n.locale"
            >
            </v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-edit-customer-tag', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { required } from '@core/utils/validation'
import customerGroup from '@/api/customer/customerGroup'
import discountType from '@/@fake-db/data/discountType.json'
import store from '@/store'

export default {
  model: {
    prop: 'isEditCustomerGroup',
    event: 'update:is-edit-customer-tag',
  },
  props: {
    shopName: {
      type: String,
      default: '',
    },
    isEditCustomerGroup: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const formEditCustomerGroup = ref(null)
    const loading = ref(false)
    const discountTypeList = ref(discountType.data)
    const dataEditLocal = ref({})
    const { updateCustomerGroup } = customerGroup
    const updateCustomerGroupData = () => {
      const isInvalidForm = formEditCustomerGroup.value.validate()
      if (!isInvalidForm) return
      loading.value = true
      delete dataEditLocal.value.delete
      delete dataEditLocal.value.customer_group_save_name
      const body = dataEditLocal.value
      updateCustomerGroup(body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('update:is-edit-customer-tag', false)
        emit('onUpdate', res.response)
      })
    }
    watch(
      () => props.dataEdit,
      () => {
        dataEditLocal.value = JSON.parse(JSON.stringify(props.dataEdit))
      },
    )

    return {
      formEditCustomerGroup,
      loading,
      discountTypeList,
      dataEditLocal,
      required,
      updateCustomerGroupData,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
