import customerGroup from '@/api/customer/customerGroup'
import shop from '@/api/shop'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function userCustomerGroup() {
  const loadingDelete = ref(false)
  const searchQuery = ref('')
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const isDeleteCustomerGroup = ref(false)
  const customer_group_id = ref('')
  const shop_name = ref('')
  const totalPage = ref(0)
  const segmentId = ref(0)

  const tableColumns = [
    {
      text: '#',
      value: 'customer_group_id',
      width: '50',
      align: 'center',
    },
    { text: i18n.t('group_customers'), value: 'customer_group_name', align: 'center' },
    { text: i18n.t('discount_amount'), value: 'customer_group_save', align: 'center' },
    { text: i18n.t('discount_type'), value: 'customer_group_save_name', align: 'center' },
    { text: i18n.t('option'), value: 'actions', align: 'center' },
  ]
  const loading = ref(false)
  const options = ref({})
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const { shop: getShop } = shop
  getShop().then(res => {
    shop_name.value = res.shop_name
  })
  const { customerGroupList, deleteCustomerGroup } = customerGroup

  const fetchDataTable = event => {
    loading.value = true
    const body = {
      searchtext: searchQuery.value,
      lang: i18n.locale,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }
    customerGroupList(body).then(res => {
      const { count, count_of_page, data, segment } = res
      dataTableList.value = data
      totalDataTableList.value = count
      segmentId.value = segment
      totalPage.value = count_of_page

      if (event == 'add') {
        options.value.page = count_of_page
      } else if (event == 'delete') {
        if (+options.value.page > +count_of_page) {
          options.value.page = count_of_page
          fetchDataTable()
        }
      }
      loading.value = false
    })
  }
  const removeCustomerGroup = () => {
    loadingDelete.value = true
    deleteCustomerGroup(customer_group_id.value).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      loadingDelete.value = false
      isDeleteCustomerGroup.value = false
      fetchDataTable('delete')
    })
  }

  watch([searchQuery, options], (newvalue, oldvalue) => {
    loading.value = true
    if (newvalue[0] !== oldvalue[0]) {
      options.value.page = 1
    }
    fetchDataTable('select')
  })

  return {
    loadingDelete,
    segmentId,
    footer,
    dataTableList,
    totalDataTableList,
    customer_group_id,
    isDeleteCustomerGroup,
    removeCustomerGroup,
    searchQuery,
    loading,
    options,
    tableColumns,
    shop_name,
    totalPage,
    fetchDataTable,
  }
}
