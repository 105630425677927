<template>
  <div>
    <v-dialog
      v-model="isAddNewCustomerGroup"
      max-width="400"
      persistent
    >
      <v-card>
        <v-form
          ref="addCustomerGroupForm"
          @submit.prevent="newCustomerGroup"
        >
          <v-card-title>
            {{ $t('add_customer_groups') }}
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model.trim="shopName"
              readonly
              :label="$t('clinic_name')"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model.trim="customer_group_name"
              :label="$t('group_customers')"
              class="mt-3"
              outlined
              autofocus
              :rules="[required]"
              dense
            ></v-text-field>
            <v-text-field
              v-model.trim="customer_group_save"
              :label="$t('discount_amount')"
              type="number"
              outlined
              class="mt-3"
              dense
            ></v-text-field>
            <v-select
              v-model="save_type_id"
              dense
              outlined
              class="mt-3"
              :label="$t('discount_type')"
              item-value="id"
              :items="discountTypeList"
              :item-text="$i18n.locale"
            >
            </v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="loading"
              type="submit"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-add-new-customer-group', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { required } from '@core/utils/validation'
import customerGroup from '@/api/customer/customerGroup'
import discountType from '@/@fake-db/data/discountType.json'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewCustomerGroup',
    event: 'update:is-add-new-customer-group',
  },
  props: {
    shopName: {
      type: String,
      default: '',
    },
    isAddNewCustomerGroup: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const addCustomerGroupForm = ref(null)
    const loading = ref(false)
    const discountTypeList = ref(discountType.data)
    const save_type_id = ref('1')
    const customer_group_save = ref(0)
    const customer_group_name = ref('')
    const { addCustomerGroup } = customerGroup

    const newCustomerGroup = () => {
      const isInvalidForm = addCustomerGroupForm.value.validate()
      if (!isInvalidForm) return
      loading.value = true
      const body = {
        customer_group_name: customer_group_name.value,
        customer_group_save: customer_group_save.value,
        save_type_id: save_type_id.value,
      }
      addCustomerGroup(body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('update:is-add-new-customer-group', false)
        emit('onAdd', res.response)
        customer_group_name.value = ''
        customer_group_save.value = 0
        save_type_id.value = '1'
      })
    }
    watch(() => props.isAddNewCustomerGroup, newValue => {
      if (newValue) {
        customer_group_name.value = ''
        customer_group_save.value = 0
        save_type_id.value = '1'
      }
    })

    return {
      addCustomerGroupForm,
      loading,
      customer_group_save,
      save_type_id,
      discountTypeList,
      customer_group_name,
      newCustomerGroup,
      required,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
