<template>
  <div>
    <AddNewCustomerGroup
      v-model="isAddNewCustomerGroup"
      :shop-name="shop_name"
      @onAdd="fetchDataTable('add')"
    />
    <EditCustomerGroup
      v-model="isEditCustomerGroup"
      :shop-name="shop_name"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable('update')"
    />

    <v-card>
      <v-card-title class="px-2">
        {{ $t('group_customers') }}
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wr ap">
          <v-btn
            color="primary"
            class="d-none d-md-block"
            @click="isAddNewCustomerGroup = true"
          >
            <v-icon class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span class="d-none d-sm-block">{{ $t('add_customer_groups') }}</span>
          </v-btn>
          <v-btn
            color="primary"
            class="d-block d-sm-none"
            fab
            outlined
            small
            @click="isAddNewCustomerGroup = true"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="$t('wordingSearch')"
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableColumns"
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template #[`item.customer_group_id`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                small
                v-bind="attrs"
                v-on="on"
                @click=";(dataEdit = item), (isEditCustomerGroup = true)"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                icon
                small
                :disabled="item.delete == 2 || item.delete == '0'"
                v-bind="attrs"
                v-on="on"
                @click=";(customer_group_id = item.customer_group_id), (isDeleteCustomerGroup = true)"
              >
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
    <v-dialog
      v-model="isDeleteCustomerGroup"
      max-width="300"
      persistent
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            color="warning"
            class="me-1"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon>{{ $t('confirm_deletion') }} !
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="loadingDelete"
            :disabled="loadingDelete"
            @click="removeCustomerGroup"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isDeleteCustomerGroup = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiAlertOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import useCustomerGroup from './useCustomerGroup'
import AddNewCustomerGroup from './AddNewCustomerGroup.vue'
import EditCustomerGroup from './EditCustomerGroup.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    AddNewCustomerGroup,
    EditCustomerGroup,
    Pagination,
  },
  setup() {
    const isAddNewCustomerGroup = ref(false)
    const isEditCustomerGroup = ref(false)
    const dataEdit = ref({})

    return {
      isAddNewCustomerGroup,
      isEditCustomerGroup,
      dataEdit,
      ...useCustomerGroup(),
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiAlertOutline,
      },
    }
  },
}
</script>
